import rg4js from "raygun4js";

const errorReportingMiddleware = store => next => action => {
  try {
    return next(action);
  } catch (error) {
    console.error("Caught an exception!", error);

    rg4js("send", {
      error,
      customData: {
        action,
        state: store.getState()
      }
    });
  }
};

export default errorReportingMiddleware;
