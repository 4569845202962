import Attractor from "../assets/attractor.jpg";
import AttractorVideo from "../assets/attractor.mp4";

import React, { useEffect, useState, useContext, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

import {
  Container,
  Typography,
  Grid,
  useMediaQuery,
  Collapse,
  IconButton,
  Divider,
  Button,
  LinearProgress,
  CardMedia,
  Fade,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

import TagFilterList from "../components/TagFilterList.js";
import QuestionGalleryList from "../components/QuestionGalleryList.js";
import AnswersForm from "../components/AnswersForm.js";

import useQuery from "../hooks/useQuery.js";

import {
  setQuestions,
  questionsSelector,
  setTags,
  tagsSelector,
  toggleTagSelected,
  setAllTagsDeselected,
  selectedTagsSelector,
  setInitialTag,
  initialTagSelector,
  setOneTagSelected,
  answeredQuestionsSelector,
  popularQuestionSelector,
  controversialQuestionSelector,
  recentQuestionSelector,
  questionOfTheDaySelector,
  addAnsweredQuestion,
} from "../store/reducers/data.js";
import { showError } from "../store/reducers/notification.js";
import { getQuestions, getTags, postAnswer } from "../api.js";
import ConfigContext from "../config.js";

import { makeStyles, useTheme } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  headerContainer: {
    position: "relative",
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(14),
    },
    paddingBottom: theme.spacing(4),
  },
  headerContainerBackground: {
    position: "absolute",
    width: "100vw",
    height: "100%",
    transform: "translate3d(-50%, -50%, 0)",
    top: "50%",
    left: "50%",
    zIndex: -1,
    backgroundColor: theme.palette.attractorBackground,
  },
  headerSection: {
    marginBottom: theme.spacing(2),
    "&:last-of-type": {
      marginBottom: 0,
    },
    "& p": {
      marginBottom: theme.spacing(2),
    },
  },
  inlineLink: {
    cursor: "pointer",
    color: theme.palette.secondary.main,
    fontFamily: theme.fonts.fontBold,
  },
  ask: {
    // alignContent: "start",
    [theme.breakpoints.up("md")]: {
      marginTop: "-60px",
    },
    // backgroundImage: `url(${Attractor})`,
    // backgroundSize: "contain",
    // backgroundPosition: "center",
    // backgroundRepeat: "no-repeat",
  },
  attractor: {
    display: "none",
    position: "relative",
    width: "100%",
    // maxHeight: "100%",
    zIndex: -1,
    // height: "intrinsic",
    // objectPosition: "top right",
    // objectFit: "contain",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  buttonAsk: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  allQuestionsTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  filters: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    ...theme.typography.tag,
    textTransform: "initial",
    cursor: "pointer",
  },
  tagsExpanded: {
    marginBottom: theme.spacing(2),
  },
  questionOfTheDay: {
    position: "relative",
    padding: `${theme.spacing(6)}px ${theme.spacing(0)}px ${theme.spacing(
      2
    )}px !important`,
    [theme.breakpoints.up("sm")]: {
      padding: `${theme.spacing(8)}px ${theme.spacing(0)}px ${theme.spacing(
        4
      )}px !important`,
    },
    [theme.breakpoints.up("md")]: {
      padding: `${theme.spacing(10)}px ${theme.spacing(0)}px ${theme.spacing(
        6
      )}px !important`,
    },
  },
  fullWidthBackground: {
    position: "absolute",
    width: "100vw",
    height: "100%",
    transform: "translate3d(-50%, -50%, 0)",
    top: "50%",
    left: "50%",
    zIndex: -1,
    backgroundColor: theme.palette.grey["900"],
  },
  featured: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
  },
  allQuestions: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(4),
    },
  },
  progressBar: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
  },
  expandIconOpen: {
    transform: "rotate(180deg)",
  },
}));

const WebsiteQuestions = () => {
  const { ROUTES } = useContext(ConfigContext);
  const classes = useStyles();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));

  const query = useQuery();
  const history = useHistory();

  const intl = useIntl();

  const dispatch = useDispatch();
  const questions = useSelector(questionsSelector);
  const answeredQuestions = useSelector(answeredQuestionsSelector);

  const questionOfTheDay = useSelector(questionOfTheDaySelector);
  const popularQuestion = useSelector(popularQuestionSelector);
  const controversialQuestion = useSelector(controversialQuestionSelector);
  const recentQuestion = useSelector(recentQuestionSelector);

  const initialTag = useSelector(initialTagSelector);
  const tags = useSelector(tagsSelector);
  const selectedTags = useSelector(selectedTagsSelector);

  const [conceptExpanded, setConceptExpanded] = useState(false);
  const [tagsExpanded, setTagsExpanded] = useState(true);
  const handleTagsExpandClick = () => {
    setTagsExpanded(!tagsExpanded);
  };

  // Set selected tag on load
  useEffect(() => {
    dispatch(setInitialTag(query.get("tag")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load data
  useEffect(() => {
    (async () => {
      if (!questions) {
        try {
          const data = await getQuestions();
          dispatch(setQuestions(data));
        } catch (error) {
          dispatch(
            showError(new Error(intl.messages["error_api_get_questions"]))
          );
        }
      }

      if (!tags) {
        try {
          const data = await getTags();
          dispatch(
            setTags(
              data.map((tag) => ({
                ...tag,
                selected: initialTag ? tag.title === initialTag : false,
              }))
            )
          );
        } catch (error) {
          dispatch(showError(new Error(intl.messages["error_api_get_tags"])));
        }
      }
    })();
    return () => {};
  }, [dispatch, intl, questions, tags, initialTag]);

  const onClearFilters = (tagId) => {
    dispatch(setAllTagsDeselected());
  };
  const onTagClick = (tagId) => {
    if (tags.length === selectedTags.length) {
      dispatch(setOneTagSelected(tagId));
    } else {
      dispatch(toggleTagSelected(tagId));
    }
  };

  const onFormSubmit = async (value) => {
    try {
      const [id, answer] = Object.entries(value)[0];

      try {
        const response = await postAnswer(id, answer);
        if (response.status !== 200) throw new Error();
      } catch (error) {
        dispatch(
          showError(new Error(intl.messages["error_api_submit_answer"]))
        );
      }
      dispatch(addAnsweredQuestion(id));
      history.push(ROUTES.QUESTION.path.replace(":id", id));
    } catch (error) {
      console.log(error);
      dispatch(showError(error.message));
    }
  };

  return (
    <Fragment>
      <Container className={classes.headerContainer}>
        <div className={classes.headerContainerBackground} />
        <Grid container className={classes.header}>
          <Grid item xs={12} md={6}>
            <div className={classes.headerSection}>
              <Typography
                variant={upMd ? "h3" : "h4"}
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: intl.formatHTMLMessage({
                    id: "app_subtitle",
                  }),
                }}
              />
            </div>
            <div className={classes.headerSection}>
              <Typography
                variant="h5"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: intl.formatHTMLMessage({
                    id: "questions_header_overview_title",
                  }),
                }}
              />
              <Typography
                variant="body1"
                component="div"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: intl.formatHTMLMessage({
                    id: "questions_header_overview_description",
                  }),
                }}
              />
            </div>

            <div className={classes.headerSection}>
              <Typography
                variant="h5"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: intl.formatHTMLMessage({
                    id: "questions_header_concept_title",
                  }),
                }}
              />
              <Typography
                variant="body1"
                component="div"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: intl.formatHTMLMessage({
                    id: "questions_header_concept_summary",
                  }),
                }}
              />
              <Typography
                variant="body1"
                component="span"
                gutterBottom
                className={classes.inlineLink}
                onClick={() => setConceptExpanded(!conceptExpanded)}
                dangerouslySetInnerHTML={{
                  __html: intl.formatHTMLMessage({
                    id: "questions_header_concept_more",
                  }),
                }}
              />

              <Collapse
                in={conceptExpanded}
                className={conceptExpanded ? classes.conceptExpanded : ""}
                timeout="auto"
              >
                <Typography
                  variant="body1"
                  component="div"
                  gutterBottom
                  dangerouslySetInnerHTML={{
                    __html: intl.formatHTMLMessage({
                      id: "questions_header_concept_details",
                    }),
                  }}
                />
              </Collapse>
            </div>
            <div className={classes.headerSection}>
              <Typography
                variant="h5"
                dangerouslySetInnerHTML={{
                  __html: intl.formatHTMLMessage({
                    id: "questions_header_trigger",
                  }),
                }}
              />
            </div>
          </Grid>
          <Grid className={classes.ask} item xs={12} md={6}>
            <Fade in={true} appear timeout={{ enter: 1000 }}>
              <CardMedia
                className={classes.attractor}
                component="video"
                src={AttractorVideo}
                poster={Attractor}
                alt="Variable Attractor"
                loop={true}
                autoPlay={true}
                muted={true}
                onMouseEnter={(event) => event.currentTarget.play()}
              />
            </Fade>
          </Grid>
        </Grid>
      </Container>

      {questionOfTheDay && tags && (
        <Container>
          <Grid container>
            <Grid item xs={12} className={classes.questionOfTheDay}>
              <div className={classes.fullWidthBackground} />
              <Typography
                variant="h5"
                component="h2"
                color="secondary"
                gutterBottom
                style={{
                  textAlign: upMd ? "left" : "center",
                }}
              >
                {intl.messages["questions_of_the_day"]}
              </Typography>
              <AnswersForm
                form="question-of-the-day-answer-form"
                question={questionOfTheDay}
                tags={questionOfTheDay.tags
                  .map((id) => tags.find((tag) => tag.id === id)?.title)
                  .filter(Boolean)
                  .join(" / ")}
                onFormSubmit={onFormSubmit}
              />
            </Grid>
          </Grid>
        </Container>
      )}

      {questions && tags ? (
        <Fragment>
          <div className={classes.featured}>
            <QuestionGalleryList
              listId="featured"
              questions={[
                {
                  ...popularQuestion,
                  header: intl.messages["questions_most_popular"],
                },
                {
                  ...controversialQuestion,
                  header: intl.messages["questions_most_controversial"],
                },
                {
                  ...recentQuestion,
                  header: intl.messages["questions_most_recent"],
                },
              ]}
              answeredQuestions={[]}
              tags={tags}
              onFormSubmit={onFormSubmit}
            />
          </div>
          <Container>
            <Grid container className={classes.allQuestions}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  component="h2"
                  color="secondary"
                  gutterBottom
                  className={classes.allQuestionsTitle}
                >
                  <span>{intl.messages["questions_all"]}</span>
                  <Link to={ROUTES.ASK.path}>
                    <Button
                      className={classes.buttonAsk}
                      color="default"
                      variant="outlined"
                      size="large"
                    >
                      {intl.messages["cta_ask"]}
                    </Button>
                  </Link>
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.filters}
                  onClick={handleTagsExpandClick}
                >
                  <span>{intl.messages["filters"]}</span>
                  <IconButton
                    size="small"
                    className={classes.expand}
                    onClick={handleTagsExpandClick}
                    aria-expanded={tagsExpanded}
                    aria-label="Expand"
                  >
                    <ExpandMore
                      className={`${classes.expandIcon} ${
                        tagsExpanded ? classes.expandIconOpen : ""
                      }`}
                    />
                  </IconButton>
                </Typography>
                <Collapse
                  in={tagsExpanded}
                  className={tagsExpanded ? classes.tagsExpanded : ""}
                  timeout="auto"
                >
                  <TagFilterList
                    tags={tags}
                    onClearFilters={onClearFilters}
                    onTagClick={onTagClick}
                  />
                </Collapse>
                <Divider />
              </Grid>
            </Grid>
          </Container>

          <QuestionGalleryList
            listId="full-list"
            questions={
              tags.length === selectedTags.length || selectedTags.length === 0
                ? questions
                : questions.filter((question) =>
                    (question.tags || []).some((tag) =>
                      selectedTags.map(({ id }) => id).includes(tag)
                    )
                  )
            }
            answeredQuestions={answeredQuestions}
            tags={tags}
            onFormSubmit={onFormSubmit}
          />
          <Container>
            <Grid item xs={12}>
              <div style={{ height: 210 }} />
            </Grid>
          </Container>
        </Fragment>
      ) : (
        <Container>
          <Grid item xs={12}>
            <LinearProgress
              variant="query"
              color="secondary"
              className={classes.progressBar}
            />
          </Grid>
        </Container>
      )}
    </Fragment>
  );
};

export default WebsiteQuestions;
