import React, { Fragment, useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

import {
  Collapse,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import QuestionGalleryCard from "./QuestionGalleryCard.js";
import AnswersForm from "./AnswersForm.js";

import { showWarning } from "../store/reducers/notification.js";

import ConfigContext from "../config.js";

import { makeStyles, useTheme } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiCollapse-hidden": {
      position: "absolute",
      marginBottom: theme.spacing(0),
    },
    "& .MuiCollapse-container": {
      // overflowX: "visible !important",
      // overflowY: "hidden !important",
    },
  },
  collapse: {
    position: "relative",
    flexGrow: "0",
    maxWidth: "100%",
    flexBasis: "100%",
    marginBottom: theme.spacing(2),
  },
  questionOfTheDay: {
    position: "relative",
    padding: `${theme.spacing(6)}px ${theme.spacing(2)}px ${theme.spacing(
      2
    )}px !important`,
    [theme.breakpoints.up("sm")]: {
      padding: `${theme.spacing(8)}px ${theme.spacing(2)}px ${theme.spacing(
        4
      )}px !important`,
    },
    [theme.breakpoints.up("md")]: {
      padding: `${theme.spacing(10)}px ${theme.spacing(2)}px ${theme.spacing(
        6
      )}px !important`,
    },
  },
  fullWidthBackground: {
    position: "absolute",
    width: "100vw",
    height: "100%",
    transform: "translate3d(-50%, -50%, 0)",
    top: "50%",
    left: "50%",
    zIndex: -1,
    backgroundColor: theme.palette.grey["900"],
  },
}));

let timeoutId0;
let timeoutId1;
let timeoutId2;
let timeoutId3;
const collapseTimeout = { appear: 600, enter: 600, exit: 400 };

const scrollToElement = (element) => {
  const rect = element.getBoundingClientRect();

  window.scroll({
    top: rect.top + window.scrollY,
    left: 0,
    behavior: "smooth",
  });
};

const QuestionGalleryList = ({
  questions,
  answeredQuestions,
  tags,
  listId = "0",
  onFormSubmit,
}) => {
  const { ROUTES } = useContext(ConfigContext);
  const classes = useStyles();
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up("sm"));
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);

  const cardPerRow = upMd ? 3 : upSm ? 2 : 1;
  const groupedQuestions = Object.entries(questions)
    .filter(([_, question]) => question)
    // groupBy cardPerRow
    .reduce(
      (r, e, i) =>
        (i % cardPerRow ? r[r.length - 1].push(e) : r.push([e])) && r,
      []
    );

  useEffect(() => {
    return () => {
      if (timeoutId0) clearTimeout(timeoutId0);
      if (timeoutId1) clearTimeout(timeoutId1);
      if (timeoutId2) clearTimeout(timeoutId2);
      if (timeoutId3) clearTimeout(timeoutId3);
    };
  }, []);
  useEffect(() => {
    setCurrentRowIndex(null);
    setCurrentQuestion(null);
  }, [upMd, upSm]);

  return (
    <Grid container className={classes.root}>
      {questions.length ? (
        groupedQuestions.map((row, rowIndex) => (
          <Fragment key={`${listId}-${rowIndex}`}>
            <Container>
              <Grid container spacing={4}>
                {row.map(([id, question]) => (
                  <Grid key={`${listId}-${id}`} item xs={12} sm={6} md={4}>
                    {question.header && (
                      <Typography
                        variant="h5"
                        component="h2"
                        color="secondary"
                        gutterBottom
                      >
                        {question.header}
                      </Typography>
                    )}
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={(event) => {
                        if (answeredQuestions.includes(question.id)) {
                          dispatch(
                            showWarning(
                              intl.messages["warning_already_answered"],
                              {
                                dismissAfter: 3000,
                              }
                            )
                          );
                          history.push(
                            ROUTES.QUESTION.path.replace(":id", question.id)
                          );
                        } else {
                          // Shown
                          if (currentQuestion) {
                            // Same question
                            if (question.id === currentQuestion.id) {
                              // Hide
                              setCurrentRowIndex(null);
                              timeoutId0 = setTimeout(() => {
                                setCurrentQuestion(null);
                              }, collapseTimeout.exit);
                              // Different grid row
                            } else if (Number.isInteger(currentRowIndex)) {
                              if (rowIndex !== currentRowIndex) {
                                // Hide and switch question
                                setCurrentRowIndex(null);
                                const currentTarget = event.currentTarget;
                                timeoutId1 = setTimeout(() => {
                                  setCurrentQuestion(question);
                                  setCurrentRowIndex(rowIndex);

                                  timeoutId3 = setTimeout(() => {
                                    scrollToElement(currentTarget);
                                  }, collapseTimeout.enter);
                                }, collapseTimeout.exit);
                              } else {
                                // Switch question
                                setCurrentQuestion(question);
                              }
                            } else {
                              // Hide
                              setCurrentRowIndex(null);
                              timeoutId2 = setTimeout(() => {
                                setCurrentQuestion(null);
                              }, collapseTimeout.exit);
                            }
                          } else {
                            // Show
                            setCurrentRowIndex(rowIndex);
                            setCurrentQuestion(question);

                            const currentTarget = event.currentTarget;
                            timeoutId3 = setTimeout(() => {
                              scrollToElement(currentTarget);
                            }, collapseTimeout.enter);
                          }
                        }
                      }}
                    >
                      <QuestionGalleryCard
                        question={question}
                        tags={
                          question.tags &&
                          question.tags
                            .map(
                              (id) => tags.find((tag) => tag.id === id)?.title
                            )
                            .filter(Boolean)
                            .join(" / ")
                        }
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Container>
            <Collapse
              in={
                Number.isInteger(currentRowIndex) &&
                rowIndex === currentRowIndex
              }
              appear={true}
              exit={true}
              timeout={collapseTimeout}
              className={classes.collapse}
            >
              {currentQuestion && (
                <Fragment>
                  <div className={classes.fullWidthBackground} />
                  <Container>
                    <Grid container>
                      <Grid item xs={12} className={classes.questionOfTheDay}>
                        <AnswersForm
                          key={currentQuestion.id}
                          form={listId}
                          question={currentQuestion}
                          tags={
                            currentQuestion.tags &&
                            currentQuestion.tags
                              .map(
                                (id) => tags.find((tag) => tag.id === id)?.title
                              )
                              .filter(Boolean)
                              .join(" / ")
                          }
                          onFormSubmit={onFormSubmit}
                        />
                      </Grid>
                    </Grid>
                  </Container>
                </Fragment>
              )}
            </Collapse>
          </Fragment>
        ))
      ) : (
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Alert severity="info">{intl.messages["filters_no_match"]}</Alert>
            </Grid>
          </Grid>
        </Container>
      )}
    </Grid>
  );
};

QuestionGalleryList.propTypes = {
  listId: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
  answeredQuestions: PropTypes.array,
  tags: PropTypes.array,
  onFormSubmit: PropTypes.func,
};

export default QuestionGalleryList;
