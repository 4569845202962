import React, { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

import { Container, LinearProgress } from "@material-ui/core";

import AnswersForm from "../components/AnswersForm.js";

import { showError } from "../store/reducers/notification.js";
import {
  addAnsweredQuestion,
  currentQuestionSelector,
  questionsSelector,
  setCurrentQuestion,
  setTags,
  tagsSelector,
} from "../store/reducers/data.js";

import { getQuestion, getTags, postAnswer } from "../api.js";

import ConfigContext from "../config.js";

const WebsiteContribute = () => {
  const { ROUTES } = useContext(ConfigContext);
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const { id } = useParams();

  const questions = useSelector(questionsSelector);
  const tags = useSelector(tagsSelector);
  const currentQuestion = useSelector(currentQuestionSelector);

  useEffect(() => {
    (async () => {
      try {
        let data;
        if (!questions) {
          data = await getQuestion(id);
        } else {
          data = questions.find((question) => question.id === id);
        }
        if (!data) throw new Error();

        dispatch(setCurrentQuestion({ id, ...data }));
      } catch (error) {
        dispatch(showError(new Error(intl.messages["error_api_get_question"])));
      }

      if (!tags) {
        try {
          const data = await getTags();
          dispatch(setTags(data.map((tag) => ({ ...tag, selected: false }))));
        } catch (error) {
          dispatch(showError(new Error(intl.messages["error_api_get_tags"])));
        }
      }
    })();
    return () => {};
  }, [dispatch, intl, questions, id, tags]);

  const onFormSubmit = async (value) => {
    try {
      const [id, answer] = Object.entries(value)[0];

      try {
        const response = await postAnswer(id, answer);
        if (response.status !== 200) throw new Error();
      } catch (error) {
        dispatch(
          showError(new Error(intl.messages["error_api_submit_answer"]))
        );
      }
      dispatch(addAnsweredQuestion(id));
      history.push(ROUTES.QUESTION.path.replace(":id", id));
    } catch (error) {
      console.log(error);
      dispatch(showError(error.message));
    }
  };

  return (
    <Container>
      {currentQuestion && tags ? (
        <AnswersForm
          form="standalone-answers"
          question={currentQuestion}
          tags={(currentQuestion.tags || [])
            .map((id) => tags.find((tag) => tag.id === id)?.title)
            .filter(Boolean)
            .join(" / ")}
          onFormSubmit={onFormSubmit}
          hasBackButton={true}
        />
      ) : (
        <LinearProgress variant="query" color="secondary" />
      )}
    </Container>
  );
};

export default WebsiteContribute;
