// colors: {
//   // DC
//   coral: '#f06e6e',
//   coralDark: '#cd2319',
//   coralLight: '#f52d2d',
//   coralTints: ['#f38b8b', '#f6a8a8', '#f9c5c5', '#fce2e2'], // 80% 60% 40% 20%

//   // AI
//   purple: '#785abe',
//   purpleDark: '#64199b',
//   purpleLight: '#8c00fa',
//   purpleTints: ['#937bcb', '#ae9cd8', '#c9bde5', '#e4def2'], // 80% 60% 40% 20%

//   // Future networks
//   blue: '#32a0f5',
//   blueDark: '#0050c8',
//   blueLight: '#00b9fa',
//   blueTints: ['#5bb3f7', '#84c6f9', '#add9fb', '#d6ecfd'], // 80% 60% 40% 20%

//   // Immersive experiences
//   green: '#50c3aa',
//   greenDark: '#007869',
//   greenLight: '#00d7b4',
//   greenTints: ['#73cfbb', '#96dbcc', '#b9e7dd', '#dcf3ee'], // 80% 60% 40% 20%

//   lightGrey: '#f0f0f0',
//   grey: '#697378',
//   darkGrey: '#3c4b55'
// }

const fontMedium = `"Neue Montreal Medium", "Helvetica", "Arial", sans-serif`;
const fontBold = `"Neue Montreal Bold", "Helvetica", "Arial", sans-serif`;
const fontMono = `"FavoritMono-Medium", "Helvetica", "Arial", monospace`;

export default {
  palette: {
    attractorBackground: "#0b0503",
    // type: "dark",
    primary: {
      main: "#32a0f5",
      light: "#00b9fa",
      dark: "#0050c8",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#f06e6e",
      dark: "#f52d2d", // Using light for better looking hover
      light: "#f52d2d",
      contrastText: "#ffffff",
    },
  },
  fonts: {
    fontMedium,
    fontBold,
    fontMono,
  },
  typography: {
    fontFamily: fontMedium,
    h1: {
      fontFamily: fontBold,
      fontSize: "5.653rem",
    },
    h2: {
      fontFamily: fontBold,
      fontSize: "3.998rem",
    },
    h3: {
      fontFamily: fontBold,
      fontSize: "2.827rem",
    },
    h4: {
      fontFamily: fontBold,
      fontSize: "1.999rem",
    },
    h5: {
      fontFamily: fontBold,
      fontSize: "1.414rem",
    },
    h6: {
      fontFamily: fontMedium,
      fontSize: "1rem",
    },
    button: {
      fontFamily: fontMono,
      fontSize: "1rem",
      textTransform: "initial",
      borderRadius: "2px",
      padding: "6px 30px",
    },
    tag: {
      textTransform: "uppercase",
      fontFamily: fontMono,
    },
  },
  overrides: {
    MuiPaper: {
      rounded: {
        "border-radius": "10px",
      },
    },
    MuiCard: {
      root: {
        overflow: "initial",
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: "2rem",
      },
    },
    MuiChip: {
      root: {
        "border-radius": "3px",
      },
    },
    MuiAlert: {
      standardInfo: {
        color: "#d6ecfd",
        backgroundColor: "#3c4b55",
      },
      standardError: {
        color: "#f52d2d",
      },
      icon: {
        color: "#00b9fa",
      },
    },
    MuiSelect: {
      icon: {
        fontSize: "1.5rem",
      },
    },
    MuiSnackbar: {
      root: {
        position: "relative",
      },
    },
  },
  backButton: {
    zIndex: 9999,
    position: "absolute",
    left: "40px",
    top: "40px",
  },
  flexCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  flexColumnCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  flexRowCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  drawerWidth: 240,
};
