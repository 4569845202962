module.exports = {
  host: "0.0.0.0",
  port: 5000,
  pathOpenSSL: null,
  socketFps: 10,
  websiteUrl: {
    live: [
      "https://critical-dialogues.digicatapult.org.uk",
      "https://criticaldialogues.digicatapult.org.uk"
    ],
    production: "https://www.prod.critical-dialogues.uk",
  },
  apiUrl: {
    production:
      "https://europe-west1-critical-dialogues.cloudfunctions.net/api",
    staging:
      "https://europe-west1-critical-dialogues-staging.cloudfunctions.net/api",
  },
  adminUrl: "https://critical-dialogues.web.app",
  thumbnailsUrl: "/attractors",
  firebase: {
    production: {
      apiKey: "AIzaSyDmj-nnmzFjYZWpszqq4P_OsNN6c233-AM",
      authDomain: "critical-dialogues.firebaseapp.com",
      databaseURL: "https://critical-dialogues.firebaseio.com",
      projectId: "critical-dialogues",
      storageBucket: "critical-dialogues.appspot.com",
      messagingSenderId: "728397363176",
      appId: "1:728397363176:web:51d26a94dc09cca0de165d",
    },
    staging: {
      apiKey: "AIzaSyCYUMMIYAIhB5aoHe9PJU8rp6E7A-u-ipY",
      authDomain: "critical-dialogues-staging.firebaseapp.com",
      databaseURL: "https://critical-dialogues-staging.firebaseio.com",
      projectId: "critical-dialogues-staging",
      storageBucket: "critical-dialogues-staging.appspot.com",
      messagingSenderId: "873431034222",
      appId: "1:873431034222:web:d4c77ea1dc64f669b3b97f",
    },
  },
};
