import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import {
  notificationListSelector,
  dismissNotification,
} from "../store/reducers/notification.js";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    zIndex: 1400,
  },
}));

const Notifications = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const notifications = useSelector(notificationListSelector);

  if (!notifications.length) return null;

  return (
    <div className={classes.root}>
      {notifications.map(({ id, message, type }) => (
        <Snackbar
          key={id}
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={() => dispatch(dismissNotification(id))}
            severity={type || "success"}
          >
            {message}
          </Alert>
        </Snackbar>
      ))}
    </div>
  );
};

export default Notifications;
