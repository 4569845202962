// import Background from "../assets/background.jpg";
import React, { useRef, useEffect, useContext, useState } from "react";
import { useSelector } from "react-redux";

import { CardMedia, useMediaQuery } from "@material-ui/core";

import { getGPUTier } from "detect-gpu";
import createCanvasContext from "canvas-context";

import { currentQuestionSelector } from "../store/reducers/data.js";

import ConfigContext from "../config.js";

import { getStringId } from "../utils/format.js";

import { makeStyles, useTheme } from "@material-ui/core/styles";
const useStyles = makeStyles(() => ({
  canvas: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 0,
    pointerEvents: "none",
    backgroundColor: "transparent !important",
    // backgroundImage: `url(${Background}) !important`,
    // backgroundSize: "cover !important",
    // backgroundPosition: "center !important",
    "& > *": {
      opacity: 0,
    },
  },
  videoWrapper: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 0,
    // paddingBottom: "56.25%",
    // height: 0,
  },
  video: {
    objectFit: "cover",
  },
  canvasActive: {
    pointerEvents: "all",
    filter: "none",
    "& > *": {
      opacity: 1,
    },
  },
}));

const { context } = createCanvasContext("webgl", { width: 2, height: 2 });

const getAttractorOffset = (md) => (md ? [0.5, 0] : [0, 0.15]);

const TIER_FPS = [0, 15, 25, 60];

const Attractor = ({ in: inProp }) => {
  const { thumbnailsUrl } = useContext(ConfigContext);
  const classes = useStyles();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));

  const canvasRef = useRef();
  const nodesAppRef = useRef();
  const currentQuestion = useSelector(currentQuestionSelector);
  const [fallback, setFallback] = useState(false);

  // Init Nodes app when canvas is rendered
  useEffect(() => {
    if (canvasRef && canvasRef.current) {
      (async () => {
        const gpuTier = await getGPUTier({
          glContext: context,
          desktopTiers: TIER_FPS,
          mobileTiers: TIER_FPS,
        });

        // TODO: os and browser detection for new Safari? eg. if Safari > 14 no fallback
        if (!context || [0, 1].includes(gpuTier.tier)) {
          setFallback(true);
        } else {
          const app = window.NodesApp({
            element: canvasRef.current,
            gpuTier,
            context,
          });
          await app.init();
          nodesAppRef.current = app;
          nodesAppRef.current.graph.shown = false;
          // window.app = app;
        }
      })();
    }
  }, [canvasRef, setFallback]);

  // Handle location change to show/hide attractor
  useEffect(() => {
    if (fallback) return;

    const nodesApp = nodesAppRef && nodesAppRef.current;
    if (!nodesApp) return;

    if (currentQuestion && inProp && !nodesApp.graph.shown) {
      const stringId = getStringId(currentQuestion.id, currentQuestion);
      nodesApp.graph.api.showAttractor(stringId, getAttractorOffset(upMd));
      nodesApp.graph.shown = true;
    } else if (nodesApp.graph.shown && !inProp) {
      nodesApp.graph.api.reset();
      nodesApp.graph.shown = false;
    }
  }, [upMd, currentQuestion, inProp, fallback]);

  useEffect(() => {
    const nodesApp = nodesAppRef && nodesAppRef.current;
    if (!nodesApp || !currentQuestion) return;

    if (nodesApp.graph.shown) {
      const stringId = getStringId(currentQuestion.id, currentQuestion);
      nodesApp.graph.api.showAttractor(stringId, getAttractorOffset(upMd));
    }
  }, [upMd]); // eslint-disable-line react-hooks/exhaustive-deps

  const mediaSrc =
    currentQuestion &&
    `${thumbnailsUrl}/${getStringId(currentQuestion.id, currentQuestion)}-loop`;

  return (
    <div
      ref={canvasRef}
      className={`${classes.canvas} ${inProp ? classes.canvasActive : ""}`}
    >
      {fallback && currentQuestion && inProp && (
        <div className={classes.videoWrapper}>
          <CardMedia
            component="video"
            className={classes.video}
            height="100%"
            src={`${mediaSrc}.mp4`}
            poster={`${mediaSrc}.jpg`}
            alt={currentQuestion.title}
            title={currentQuestion.title}
            autoPlay={true}
            loop={true}
            muted={true}
          />
        </div>
      )}
    </div>
  );
};

export default Attractor;
