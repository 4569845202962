import * as firebase from "firebase/app";

import { IS_PROD } from "./constants.js";

import CONFIG from "../../config/index.js";

const firebaseConfig = IS_PROD
  ? CONFIG.firebase.production
  : CONFIG.firebase.staging;

firebase.initializeApp(firebaseConfig);

export default firebase;
