import "../../index.css";

import "./bundle.js";

import firebase from "../../firebase.js";
import "firebase/functions";

import config from "../../../../config/index.js";
import * as serviceWorker from "../../serviceWorker.js";

import React from "react";
import ReactDOM from "react-dom";

import Providers from "./Providers.js";
import Website from "../../containers/Website.js";

firebase.functions();

// Render
let CONFIG = {
  thumbnailsUrl: config.thumbnailsUrl,
};

const render = (Component) => {
  return ReactDOM.render(
    <Providers config={CONFIG}>
      <Component />
    </Providers>,
    document.getElementById("root")
  );
};

if (module.hot) {
  module.hot.accept("../../containers/Website", async () => {
    const NextWebsite = require("../../containers/Website").default;
    render(NextWebsite);
  });
}

// Kick off
(async () => {
  CONFIG.COPY = (
    await import(/* webpackChunkName: "locales.js" */ "./locales.js")
  ).default;

  render(Website);

  serviceWorker.unregister();
})();
