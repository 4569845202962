import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import { LinearProgress, Container } from "@material-ui/core";

import QuestionData from "../components/QuestionData.js";

import {
  currentQuestionSelector,
  questionsSelector,
  setCurrentQuestion,
  tagsSelector,
  setTags,
} from "../store/reducers/data.js";
import { showError } from "../store/reducers/notification.js";

import { getQuestion, getTags } from "../api.js";

const WebsiteQuestion = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { id } = useParams();

  const questions = useSelector(questionsSelector);
  const tags = useSelector(tagsSelector);
  const currentQuestion = useSelector(currentQuestionSelector);

  useEffect(() => {
    (async () => {
      try {
        let data;
        if (!questions) {
          data = await getQuestion(id);
        } else {
          data = questions.find((question) => question.id === id);
        }
        if (!data) throw new Error();

        dispatch(setCurrentQuestion({ id, ...data }));
      } catch (error) {
        dispatch(showError(new Error(intl.messages["error_api_get_question"])));
      }

      if (!tags) {
        try {
          const data = await getTags();
          dispatch(setTags(data.map((tag) => ({ ...tag, selected: false }))));
        } catch (error) {
          dispatch(showError(new Error(intl.messages["error_api_get_tags"])));
        }
      }
    })();
    return () => {};
  }, [dispatch, intl, questions, tags, id]);

  // Reset current question on unmount
  useEffect(() => {
    return () => {
      dispatch(setCurrentQuestion(null));
    };
  }, [dispatch]);

  return (
    <Container>
      {currentQuestion && tags ? (
        <QuestionData
          title={currentQuestion.title}
          answers={currentQuestion.answers}
          tags={(currentQuestion.tags || [])
            .map((id) => tags.find((tag) => tag.id === id)?.title)
            .filter(Boolean)
            .join(" / ")}
        />
      ) : (
        <LinearProgress variant="query" color="secondary" />
      )}
    </Container>
  );
};

export default WebsiteQuestion;
