import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import { reduxForm, Field, propTypes } from "redux-form";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

import {
  Button,
  FormControlLabel,
  Radio,
  Divider,
  Typography,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import { ArrowForward, ArrowBack } from "@material-ui/icons";

import FormRadioGroup from "./FormRadioGroup.js";
import ConfigContext from "../config.js";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";
const useStyles = makeStyles((theme) => ({
  form: {},
  header: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    marginBottom: theme.spacing(4),
  },
  field: {
    width: "100%",
  },
  title: {
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(4),
    },
  },
  tag: {
    ...theme.typography.tag,
    color: theme.palette.secondary.main,
  },
  formControlLabel: {
    justifyContent: "space-between",
    fontSize: theme.typography.h6.fontSize,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
  label: {
    textAlign: "left",
    fontSize: theme.typography.h6.fontSize,
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
  ctas: {
    "& .MuiButton-root": {
      [theme.breakpoints.only("xs")]: {
        width: `calc(100% - ${theme.spacing(1)}px)`,
      },
    },
  },
  buttonBack: {
    border: `1px solid ${theme.palette.secondary.main}`,
  },
}));

const AnswersForm = ({
  question,
  tags,
  pristine,
  submitting,
  handleSubmit,
  onFormSubmit,
  hasBackButton,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const { ROUTES } = useContext(ConfigContext);
  const intl = useIntl();

  const answers = question.answers;

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit((values) => onFormSubmit(values))}
    >
      <Grid container>
        <Grid className={classes.header} item xs={12} md={6}>
          <Typography
            variant={upMd ? "h3" : "h5"}
            gutterBottom
            className={classes.title}
          >
            {question.title}
          </Typography>
          <p className={classes.tag}>{tags || <br />}</p>
        </Grid>
        <Grid className={classes.header} item xs={12} md={6}>
          <Field
            className={classes.field}
            name={question.id}
            component={FormRadioGroup}
            formControlProps={{ style: { width: "100%" } }}
          >
            {Object.entries(answers)
              .filter(([_, answer]) => answer)
              .map(([title, answer], answerIndex) => (
                <Fragment key={answerIndex}>
                  <FormControlLabel
                    value={title}
                    label={title}
                    control={<Radio />}
                    labelPlacement="start"
                    className={classes.formControlLabel}
                    classes={{
                      label: classes.label,
                    }}
                  />
                  {answerIndex !== Object.keys(answers).length - 1 && (
                    <Divider />
                  )}
                </Fragment>
              ))}
          </Field>
        </Grid>
      </Grid>
      <Grid className={classes.ctas} container justify="center">
        {hasBackButton && (
          <Grid item xs={6}>
            <Link to={ROUTES.QUESTIONS.path} replace>
              <Button
                type="button"
                color="default"
                variant="outlined"
                size="large"
                startIcon={<ArrowBack />}
                disabled={submitting}
                className={classes.buttonBack}
              >
                {intl.messages["form_back"]}
              </Button>
            </Link>
          </Grid>
        )}
        <Grid container item xs={hasBackButton ? 6 : 12} justify="flex-end">
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            size="large"
            endIcon={<ArrowForward />}
            disabled={pristine || submitting}
          >
            {submitting
              ? intl.messages["form_submitting"]
              : intl.messages["form_submit"]}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

AnswersForm.propTypes = {
  ...propTypes,
  question: PropTypes.object.isRequired,
  tags: PropTypes.string,
  hasBackButton: PropTypes.bool,
  onFormSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (_, ownProps) => ({
  form: ownProps.form,
});

export default compose(connect(mapStateToProps), reduxForm())(AnswersForm);
