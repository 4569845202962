import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import data from "../../store/reducers/data.js";
import notification from "../../store/reducers/notification.js";

const reducers = combineReducers({
  data,
  notification,
  form: formReducer,
});

export default reducers;
