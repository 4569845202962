// import WebsiteHome from "../../containers/WebsiteHome.js";
import WebsiteQuestions from "../../containers/WebsiteQuestions.js";
import WebsiteAsk from "../../containers/WebsiteAsk.js";
import WebsiteQuestion from "../../containers/WebsiteQuestion.js";
import WebsiteContribute from "../../containers/WebsiteContribute.js";
// import Blank from "../../containers/Blank.js";

// TODO: react-router basename doesn't work so man
const basename = "";
// const basename = process.env.PUBLIC_URL;

export default {
  // HOME: {
  //   path: basename + "/",
  //   Component: WebsiteHome,
  //   title: "home_page_title",
  // },
  QUESTION: {
    path: basename + "/questions/:id",
    Component: WebsiteQuestion,
    title: "question_page_title",
  },
  QUESTIONS: {
    // path: basename + "/questions",
    path: basename + "/",
    Component: WebsiteQuestions,
    title: "questions_page_title",
  },
  ASK: {
    path: basename + "/ask",
    Component: WebsiteAsk,
    title: "asked_question_page_title",
  },
  CONTRIBUTE: {
    path: basename + "/contribute/:id",
    Component: WebsiteContribute,
    title: "contribute_page_title",
  },
};
