import digitalCatapultLogo from "../assets/digital-catapult-logo.svg";

import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { Typography, useMediaQuery, useTheme } from "@material-ui/core";

import ConfigContext from "../config";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  mainHeader: {
    zIndex: 1,
    position: "relative",
    display: "flex",
    alignItems: "center",
    flexDirection: "column-reverse",
    textAlign: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  logo: {
    height: "60px",
  },
}));

const WebsiteHeader = () => {
  const { ROUTES } = useContext(ConfigContext);
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div className={classes.mainHeader}>
      <Link to={ROUTES.QUESTIONS.path}>
        <Typography
          variant={upMd ? "h5" : "h6"}
          component="h1"
          color="textPrimary"
        >
          {intl.messages["app_title"]}
        </Typography>
      </Link>
      <a
        href={intl.messages["digital_catapult_url"]}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={digitalCatapultLogo}
          className={classes.logo}
          alt={intl.messages["app_title"]}
        />
      </a>
    </div>
  );
};

export default WebsiteHeader;
