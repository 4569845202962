import React from "react";
import PropTypes from "prop-types";
import { fieldPropTypes } from "redux-form";

import { TextField } from "@material-ui/core";

const FormTextField = ({
  input,
  meta: { touched, invalid, error },
  label,
  ...props
}) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...props}
  />
);

FormTextField.propTypes = {
  ...fieldPropTypes,
  label: PropTypes.string
};

export default FormTextField;
