import { IS_PROD } from "./constants.js";

import CONFIG from "../../config/index.js";

const API_URL = IS_PROD ? CONFIG.apiUrl.production : CONFIG.apiUrl.staging;

const GET_OPTIONS = {
  method: "GET",
  mode: "cors",
};

const POST_OPTIONS = {
  method: "POST",
  mode: "cors",
  headers: {
    "Content-Type": "application/json",
  },
};

const getQuestions = async () =>
  await (await fetch(`${API_URL}/questions`, GET_OPTIONS)).json();

const getQuestion = async (id) =>
  await (await fetch(`${API_URL}/questions/${id}`, GET_OPTIONS)).json();

const postAnswer = async (id, answer) =>
  await fetch(`${API_URL}/answer`, {
    ...POST_OPTIONS,
    body: JSON.stringify({ id, answer }),
  });

const postAskedQuestion = async (title) =>
  await fetch(`${API_URL}/asked-question`, {
    ...POST_OPTIONS,
    body: JSON.stringify({ title }),
  });

const getTags = async () =>
  await (await fetch(`${API_URL}/tags`, GET_OPTIONS)).json();

export { getQuestions, getQuestion, postAnswer, postAskedQuestion, getTags };
