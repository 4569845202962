import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

import { Grid, useMediaQuery, Typography, Container } from "@material-ui/core";

import AskQuestionForm from "../components/AskQuestionForm.js";

import { showError, showSuccess } from "../store/reducers/notification.js";
import { postAskedQuestion } from "../api.js";
import ConfigContext from "../config.js";

import { makeStyles, useTheme } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.only("xs")]: {
      textAlign: "center",
    },
  },
}));

const WebsiteAsk = () => {
  const { ROUTES } = useContext(ConfigContext);
  const classes = useStyles();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();

  const onFormSubmit = async (values) => {
    const title = values.question;

    try {
      const response = await postAskedQuestion(title);
      if (response.status !== 200) throw new Error();

      dispatch(
        showSuccess(intl.formatMessage({ id: "on_asked_question_added" }), {
          dismissAfter: 3000,
        })
      );
    } catch (error) {
      dispatch(
        showError(new Error(intl.messages["error_api_add_asked_question"]))
      );
    }
    history.push(ROUTES.QUESTIONS.path);
  };

  return (
    <Container>
      <Grid className={classes.header} item>
        <Typography
          variant={upMd ? "h2" : "h3"}
          gutterBottom
          dangerouslySetInnerHTML={{
            __html: intl.formatHTMLMessage({
              id: "asked_question_page_title",
            }),
          }}
        />
        <Typography
          variant="body1"
          component="div"
          dangerouslySetInnerHTML={{
            __html: intl.formatHTMLMessage({
              id: "asked_question_page_description",
            }),
          }}
        />
      </Grid>
      <Grid container justify="center">
        <Grid className={classes.header} item xs={12} md={12}>
          <AskQuestionForm
            onFormSubmit={onFormSubmit}
            backTo={ROUTES.QUESTIONS.path}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default WebsiteAsk;
