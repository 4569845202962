import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
// import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

import {
  Grid,
  Typography,
  Divider,
  useMediaQuery,
  IconButton,
  Collapse,
  Button,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";

import percentRound from "percent-round";

// import { answeredQuestionsSelector } from "../store/reducers/data.js";
import Sharer from "../utils/Sharer.js";
import ConfigContext from "../config.js";

import { makeStyles, useTheme } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: 0,
    left: 0,
    padding: theme.spacing(4),
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    [theme.breakpoints.up("md")]: {
      position: "initial",
      padding: 0,
      backgroundColor: "transparent",
    },
  },
  header: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "640px",
    },
  },
  footer: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "640px",
    },
  },
  title: {
    fontFamily: theme.fonts.fontBold,
    position: "relative",
    paddingRight: theme.spacing(8),
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(0),
    },
  },
  tag: {
    ...theme.typography.tag,
    color: theme.palette.secondary.main,
  },
  data: {
    maxWidth: "640px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
  },
  listItem: {
    flexWrap: "initial",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
      width: `calc(50% - ${theme.spacing(4)}px)`,
    },
    marginBottom: theme.spacing(2),
  },
  listItemNumber: {
    minWidth: "60px",
    [theme.breakpoints.up("md")]: {
      minWidth: "initial",
    },
  },
  divider: {
    height: 3,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(0.5),
      marginLeft: 0,
      marginRight: 0,
    },
    backgroundColor: theme.palette.secondary.main,
  },
  buttonHome: {
    pointerEvents: "all",
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  footerItem: {
    [theme.breakpoints.up("md")]: {
      width: `calc(50% - ${theme.spacing(4)}px)`,
    },
  },
  buttonsSocialWrapper: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  buttonsSocial: {
    pointerEvents: "all",
    minWidth: "auto",
    width: "42px",
    height: "42px",
    padding: 0,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      "&:first-of-type": {
        marginLeft: 0,
      },
    },
    "& svg": {
      maxHeight: theme.spacing(3),
    },
  },
  expand: {
    pointerEvents: "all",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    display: "block",
    position: "absolute",
    top: 0,
    right: 0,
    transform: "rotate(0deg)",
    marginLeft: "auto",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  expandIcon: {
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandIconOpen: {
    transform: "rotate(-45deg)",
  },
}));

const QuestionData = ({ title, answers, tags }) => {
  const classes = useStyles();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const intl = useIntl();
  const { ROUTES } = useContext(ConfigContext);

  const [expanded, setExpanded] = useState(false);

  // TODO: need answered stored
  // const answeredQuestions = useSelector(answeredQuestionsSelector);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const percentages = percentRound(
    Object.values(answers).map((v) => v.count + (v.publicCount || 0))
  );

  const dayUid = new Date().toLocaleDateString("en-GB").replace(/\//g, "");
  const url = `${window.location.href}?${dayUid}`;

  return (
    <Grid className={classes.root} container direction="column">
      <Grid className={classes.header} item xs={12} md={5}>
        <Typography
          variant={upMd ? "h3" : "h6"}
          gutterBottom
          className={classes.title}
        >
          <span>{title}</span>
          <IconButton
            size="small"
            className={classes.expand}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="Expand"
          >
            <Add
              className={`${classes.expandIcon} ${
                expanded ? classes.expandIconOpen : ""
              }`}
            />
          </IconButton>
        </Typography>
        {upMd && <p className={classes.tag}>{tags || <br />}</p>}
      </Grid>
      <Collapse in={upMd || expanded} timeout={upMd ? 0 : "auto"}>
        <Grid className={classes.data} item xs={12} md={5}>
          <Grid
            className={classes.list}
            container
            wrap="wrap"
            justify="space-between"
          >
            {Object.entries(answers)
              .filter(([_, answer]) => answer)
              .map(([title, answer], answerIndex) => (
                <Grid
                  key={answerIndex}
                  className={classes.listItem}
                  item
                  container
                >
                  <Typography
                    variant={upMd ? "h2" : "h4"}
                    component="p"
                    className={classes.listItemNumber}
                  >
                    {percentages[answerIndex]}
                    <Typography variant={upMd ? "h3" : "h5"} component="span">
                      %
                    </Typography>
                  </Typography>
                  <Divider
                    className={classes.divider}
                    orientation={upMd ? "horizontal" : "vertical"}
                  />
                  <Typography variant="body1" component="p">
                    {title}
                  </Typography>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid className={classes.footer} item xs={12} md={5}>
          <Grid
            container
            justify="space-between"
            wrap={upMd ? "wrap" : "nowrap"}
          >
            <Grid item className={classes.footerItem}>
              <Link to={ROUTES.QUESTIONS.path}>
                <Button
                  color="default"
                  variant="outlined"
                  size="large"
                  className={classes.buttonHome}
                >
                  {intl.messages["cta_go_home"]}
                </Button>
              </Link>
            </Grid>
            <Grid
              item
              className={`${classes.footerItem} ${classes.buttonsSocialWrapper}`}
            >
              <Button
                className={classes.buttonsSocial}
                color="secondary"
                variant="contained"
                onClick={() =>
                  Sharer.share("twitter", url, intl.messages["share_message"])
                }
              >
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.79552 23C19.3613 23 25.1429 14.1486 25.1429 6.48573C25.1429 6.23671 25.1429 5.9877 25.1317 5.73868C26.2521 4.92372 27.2269 3.8937 28 2.72785C26.9692 3.19193 25.8599 3.49754 24.6947 3.64469C25.8824 2.93159 26.7899 1.78839 27.2269 0.430118C26.1176 1.09793 24.8852 1.57333 23.5742 1.83366C22.521 0.701771 21.0308 0 19.3838 0C16.2129 0 13.6359 2.60335 13.6359 5.80659C13.6359 6.25935 13.6919 6.70079 13.7815 7.1309C9.0084 6.89321 4.77311 4.57283 1.93838 1.06398C1.44538 1.92421 1.16527 2.92028 1.16527 3.98425C1.16527 5.99902 2.18487 7.77608 3.71989 8.81742C2.77871 8.78346 1.89356 8.52313 1.12045 8.09301C1.12045 8.11565 1.12045 8.13829 1.12045 8.17224C1.12045 10.9793 3.10364 13.3337 5.72549 13.8656C5.2437 14.0015 4.7395 14.0694 4.21289 14.0694C3.84314 14.0694 3.48459 14.0354 3.13725 13.9675C3.86555 16.2766 5.9944 17.9518 8.5042 17.997C6.53221 19.5591 4.05602 20.4872 1.36695 20.4872C0.907564 20.4872 0.448179 20.4646 0 20.408C2.53221 22.0379 5.55742 23 8.79552 23Z"
                    fill="#fff"
                  />
                </svg>
              </Button>
              <Button
                className={classes.buttonsSocial}
                color="secondary"
                variant="contained"
                onClick={() =>
                  Sharer.share("linkedin", url, intl.messages["share_message"])
                }
              >
                <svg
                  width="35"
                  height="34"
                  viewBox="0 0 35 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.4371 33.1885V11.3763H1.18712V33.1885H8.4371V33.1885ZM4.81207 8.39817C7.34029 8.39817 8.91389 6.72324 8.91389 4.63005C8.86677 2.48975 7.34029 0.861293 4.86004 0.861293C2.38004 0.861293 0.758545 2.48975 0.758545 4.63005C0.758545 6.72324 2.33181 8.39817 4.76482 8.39817H4.81194H4.81207Z"
                    fill="white"
                  />
                  <path
                    d="M12.4421 33.1885H19.692V21.0076C19.692 20.3557 19.7392 19.7045 19.9306 19.2384C20.4547 17.9359 21.6476 16.587 23.6504 16.587C26.2739 16.587 27.3233 18.5872 27.3233 21.5195V33.1885H34.5726V20.6817C34.5726 13.9819 30.9959 10.8644 26.226 10.8644C22.3148 10.8644 20.5978 13.0506 19.6438 14.5396H19.6922V11.3763H12.4423C12.5375 13.423 12.4423 33.1885 12.4423 33.1885H12.4421Z"
                    fill="white"
                  />
                </svg>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
};

QuestionData.propTypes = {
  title: PropTypes.string.isRequired,
  answers: PropTypes.object,
  tags: PropTypes.string,
};

export default QuestionData;
