import React, { Fragment, useMemo } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { Router } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import { IntlProvider } from "react-intl";
import { createBrowserHistory } from "history";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

import ConfigContext from "../../config.js";
import theme from "../../theme.js";
import ROUTES from "./routes.js";
import rootReducer from "./rootReducer.js";
import configureStore from "../../store/index.js";

const store = configureStore(rootReducer);

const history = createBrowserHistory();
// const history = createBrowserHistory({ basename: process.env.PUBLIC_URL });

let lastLocation = {};
history.listen((location) => {
  lastLocation = location;
});

// monkey patching to prevent pushing same url into history stack
const prevHistoryPush = history.push;
history.push = (pathname, state = {}) => {
  if (
    lastLocation === null ||
    pathname !==
      lastLocation.pathname + lastLocation.search + lastLocation.hash ||
    JSON.stringify(state) !== JSON.stringify(lastLocation.state)
  ) {
    prevHistoryPush(pathname, state);
  }
};

const Providers = ({ children, config }) => {
  const prefersDarkMode = true;
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const muiTheme = useMemo(
    () =>
      createMuiTheme({
        ...theme,
        palette: {
          ...theme.palette,
          type: prefersDarkMode ? "dark" : "light",
          ...(prefersDarkMode
            ? {
                background: {
                  default: "#111111",
                  paper: "#222222",
                },
              }
            : {}),
        },
      }),
    [prefersDarkMode]
  );

  return (
    <ConfigContext.Provider value={{ ...config, ROUTES }}>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        <ReduxProvider store={store}>
          <IntlProvider
            locale="en-GB"
            messages={config.COPY}
            textComponent={Fragment}
          >
            <Router history={history}>
              <LastLocationProvider>{children}</LastLocationProvider>
            </Router>
          </IntlProvider>
        </ReduxProvider>
      </MuiThemeProvider>
    </ConfigContext.Provider>
  );
};

export default Providers;
