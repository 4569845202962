import React, { useContext } from "react";
import PropTypes from "prop-types";

import LazyLoad from "react-lazyload";
import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";

import { getStringId } from "../utils/format.js";

import ConfigContext from "../config.js";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    overflow: "hidden",
    backgroundColor: "transparent",
    // "&:hover": {
    //   backgroundColor: theme.palette.grey["800"],
    // },
  },
  media: {
    borderRadius: "10px !important",
    overflow: "hidden",
  },
  mediaImg: {
    width: "100%",
    objectFit: "cover",
    backgroundColor: theme.palette.common.black,
  },
  cardContent: {
    paddingLeft: `0 !important`,
    paddingRight: `0 !important`,
  },
  tag: {
    ...theme.typography.tag,
    color: theme.palette.secondary.main,
  },
}));

const QuestionGalleryCard = ({ question, tags }) => {
  const classes = useStyles();
  const CONFIG = useContext(ConfigContext);

  const mediaSrc = `${CONFIG.thumbnailsUrl}/${getStringId(
    question.id,
    question
  )}-loop`;

  return (
    <Card className={classes.root} elevation={0}>
      <LazyLoad height={210}>
        <CardMedia
          className={classes.media}
          component="video"
          src={`${mediaSrc}.mp4`}
          poster={`${mediaSrc}.jpg`}
          alt={question.title}
          loop={true}
          muted={true}
          onMouseEnter={(event) => event.currentTarget.play()}
          onMouseLeave={(event) => event.currentTarget.pause()}
        />
        {/* <img className={classes.mediaImg} src={imgSrc} alt={question.title} /> */}
      </LazyLoad>
      <CardContent className={classes.cardContent}>
        <Typography variant="h6" component="h3" gutterBottom>
          {question.title}
        </Typography>
        {/* <p className={classes.tag}>{tags || <br />}</p> */}
      </CardContent>
    </Card>
  );
};

QuestionGalleryCard.propTypes = {
  question: PropTypes.object.isRequired,
  tags: PropTypes.string,
};

export default QuestionGalleryCard;
