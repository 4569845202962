import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field, propTypes } from "redux-form";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

import { Button, Box } from "@material-ui/core";
import { ArrowForward, ArrowBack } from "@material-ui/icons";

import FormTextField from "./FormTextField.js";

import { required } from "../utils/form.js";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  form: {
    position: "relative",
    width: "100%",
  },
  field: {
    width: "100%",
  },
  buttonBack: {
    border: `1px solid ${theme.palette.secondary.main}`,
  },
}));

const AskQuestionForm = ({
  pristine,
  submitting,
  handleSubmit,
  onFormSubmit,
  backTo,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit((values) => onFormSubmit(values))}
    >
      <Box>
        <Field
          className={classes.field}
          name="question"
          component={FormTextField}
          multiline
          autoFocus
          rows="5"
          variant="outlined"
          color="secondary"
          validate={required}
        />
      </Box>
      <Box
        display="flex"
        justifyContent={backTo ? "space-between" : "flex-end"}
        mt={2}
      >
        {backTo && (
          <Link to={backTo}>
            <Button
              type="button"
              color="default"
              variant="outlined"
              size="large"
              startIcon={<ArrowBack />}
              disabled={submitting}
              className={classes.buttonBack}
            >
              {intl.messages["form_back"]}
            </Button>
          </Link>
        )}

        <Button
          type="submit"
          color="secondary"
          variant="contained"
          size="large"
          endIcon={<ArrowForward />}
          disabled={pristine || submitting}
        >
          {submitting
            ? intl.messages["form_submitting"]
            : intl.messages["form_submit"]}
        </Button>
      </Box>
    </form>
  );
};

AskQuestionForm.propTypes = {
  ...propTypes,
  onFormSubmit: PropTypes.func.isRequired,
  backTo: PropTypes.string,
};

export default reduxForm({ form: "ask-question" })(AskQuestionForm);
