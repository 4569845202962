import { createStore, applyMiddleware, compose } from "redux";
import ReduxThunk from "redux-thunk";
import errorReportingMiddleware from "./middleware/raygun.js";

const isDev = process.env.NODE_ENV === "development";
// if (isDev) {
//   if (module.hot) {
//     // Enable Webpack hot module replacement for reducers
//     module.hot.accept("./reducers", () => {
//       const nextRootReducer = require("../entry/admin/rootReducer.js").default;
//       store.replaceReducer(nextRootReducer);
//     });
//   }
// }

const configureStore = (rootReducer, preloadedState = {}) => {
  const extMiddleware =
    isDev && window.__REDUX_DEVTOOLS_EXTENSION__
      ? [
          window.__REDUX_DEVTOOLS_EXTENSION__({
            actionSanitizer: (action) => ({
              ...action,
              type: action.type.toString(),
            }),
          }),
          applyMiddleware(errorReportingMiddleware),
        ]
      : [applyMiddleware(errorReportingMiddleware)];

  const store = createStore(
    rootReducer,
    preloadedState,
    compose(applyMiddleware(ReduxThunk), ...extMiddleware)
  );

  return store;
};

export default configureStore;
