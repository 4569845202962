import { createAction, handleActions } from "redux-actions";
import { createSelector } from "reselect";
import seedrandom from "seedrandom";

import { getQuestionType } from "../../utils/format.js";

// Actions
export const SET_QUESTIONS = createAction("data:setQuestions");
export const SET_CURRENT_QUESTION = createAction("data:setCurrentQuestion");
export const ADD_ANSWERED_QUESTION = createAction("data:addAnsweredQuestion");
export const SET_INITIAL_TAG = createAction("data:setInitialTag");
export const SET_TAGS = createAction("data:setTags");
export const TOGGLE_TAG_SELECTED = createAction("data:setTagSelected");
export const SET_ONE_TAG_SELECTED = createAction("data:setOneTagSelected");
export const SET_ALL_TAGS_DESELECTED = createAction(
  "data:setAllTagsDeselected"
);

const defaultState = {
  questions: null,
  question: null,
  answeredQuestions: [],
  initialTag: null,
  tags: null,
};

// Reducer
const reducer = handleActions(
  {
    [SET_QUESTIONS]: (state, { payload }) => ({
      ...state,
      questions: payload,
    }),
    [SET_CURRENT_QUESTION]: (state, { payload }) => ({
      ...state,
      currentQuestion: payload,
    }),
    [ADD_ANSWERED_QUESTION]: (state, { payload }) => ({
      ...state,
      answeredQuestions: [...state.answeredQuestions, payload],
    }),
    [SET_INITIAL_TAG]: (state, { payload }) => ({
      ...state,
      initialTag: payload,
    }),
    [SET_TAGS]: (state, { payload }) => ({
      ...state,
      tags: payload,
    }),
    [TOGGLE_TAG_SELECTED]: (state, { payload }) => ({
      ...state,
      tags: [
        ...state.tags.map((tag) => {
          if (tag.id === payload) tag.selected = !tag.selected;
          return tag;
        }),
      ],
    }),
    [SET_ONE_TAG_SELECTED]: (state, { payload }) => ({
      ...state,
      tags: [
        ...state.tags.map((tag) => {
          if (tag.id === payload) {
            tag.selected = true;
          } else {
            tag.selected = false;
          }
          return tag;
        }),
      ],
    }),
    [SET_ALL_TAGS_DESELECTED]: (state) => ({
      ...state,
      tags: [
        ...state.tags.map((tag) => {
          tag.selected = false;
          return tag;
        }),
      ],
    }),
  },
  defaultState
);

// Actions creators
export const setQuestions = (payload) => ({
  type: SET_QUESTIONS,
  payload,
});
export const setCurrentQuestion = (payload) => ({
  type: SET_CURRENT_QUESTION,
  payload,
});
export const addAnsweredQuestion = (payload) => ({
  type: ADD_ANSWERED_QUESTION,
  payload,
});
export const setInitialTag = (payload) => ({
  type: SET_INITIAL_TAG,
  payload,
});
export const setTags = (payload) => ({
  type: SET_TAGS,
  payload,
});
export const toggleTagSelected = (payload) => ({
  type: TOGGLE_TAG_SELECTED,
  payload,
});
export const setOneTagSelected = (payload) => ({
  type: SET_ONE_TAG_SELECTED,
  payload,
});
export const setAllTagsDeselected = () => ({
  type: SET_ALL_TAGS_DESELECTED,
});

// Helpers
const getQuestionTotalAnswersCount = (question) =>
  Object.values(question.answers)
    .map(({ count = 0, publicCount = 0 }) => count + publicCount)
    .reduce((a, b) => a + b, 0);

const sampleRandomFromArray = (arr, seed) => {
  const rnd = seed ? seedrandom(seed)() : Math.random();
  return arr[Math.floor(rnd * arr.length)];
};

// Selectors
export const dataSelector = (state) => state.data;
export const questionsSelector = createSelector(
  dataSelector,
  (data) => data.questions
);

export const questionOfTheDaySelector = createSelector(
  questionsSelector,
  (questions) =>
    questions &&
    sampleRandomFromArray(questions, new Date().toLocaleDateString("en-GB"))
);
export const popularQuestionSelector = createSelector(
  questionsSelector,
  (questions) =>
    questions &&
    questions.reduce((previousQuestion, currentQuestion) =>
      getQuestionTotalAnswersCount(previousQuestion) >
      getQuestionTotalAnswersCount(currentQuestion)
        ? previousQuestion
        : currentQuestion
    )
);
export const controversialQuestionSelector = createSelector(
  questionsSelector,
  popularQuestionSelector,
  (questions, popular) =>
    questions &&
    (sampleRandomFromArray(
      questions
        // Avoid duplicate with popular question
        .filter((question) => question.id !== popular.id)
        .filter((question) => getQuestionType(question) === 3)
    ) ||
      questions[1])
);
export const recentQuestionSelector = createSelector(
  questionsSelector,
  popularQuestionSelector,
  controversialQuestionSelector,
  (questions, popular, controversial) =>
    questions &&
    (questions
      // Avoid duplicate with popular question
      .filter(
        (question) => ![popular.id, controversial.id].includes(question.id)
      )
      .reduce((previousQuestion, currentQuestion) =>
        previousQuestion.createdAt._seconds > currentQuestion.createdAt._seconds
          ? previousQuestion
          : currentQuestion
      ) ||
      questions[2])
);

export const currentQuestionSelector = createSelector(
  dataSelector,
  (data) => data.currentQuestion
);
export const answeredQuestionsSelector = createSelector(
  dataSelector,
  (data) => data.answeredQuestions
);
export const initialTagSelector = createSelector(
  dataSelector,
  (data) => data.initialTag
);
export const tagsSelector = createSelector(dataSelector, (data) => data.tags);
export const selectedTagsSelector = createSelector(dataSelector, (data) =>
  data.tags && data.tags.length ? data.tags.filter((tag) => tag.selected) : []
);

export default reducer;
