import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { Chip } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  chipList: {
    "& > *": {
      margin: theme.spacing(0.5),
      ...theme.typography.tag,
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },
}));

const TagFilterList = ({ tags, onClearFilters, onTagClick }) => {
  const classes = useStyles();
  const intl = useIntl();

  const currentTags = tags.filter((tag) => tag);
  const allSelected = currentTags.every((tag) => tag.selected);
  const selectedTags = currentTags.filter((tag) => tag.selected);

  return (
    <div className={classes.chipList}>
      <Chip
        label={`${intl.messages["filters_clear_filters"]} (${selectedTags.length}/${currentTags.length})`}
        color={allSelected ? "secondary" : "default"}
        variant={allSelected ? "default" : "outlined"}
        size="small"
        style={{ textTransform: "initial" }}
        onClick={() => onClearFilters()}
      />
      {currentTags.map(({ id, title, selected }) => (
        <Chip
          key={id}
          label={title}
          color={selected ? "secondary" : "default"}
          variant={selected ? "default" : "outlined"}
          size="small"
          onClick={() => onTagClick(id)}
        />
      ))}
    </div>
  );
};

TagFilterList.propTypes = {
  tags: PropTypes.array.isRequired,
  onClearFilters: PropTypes.func,
  onTagClick: PropTypes.func,
};

export default TagFilterList;
