import { createAction, handleActions } from "redux-actions";
import { createSelector } from "reselect";

// Actions
export const NOTIFICATION_SHOW = createAction("notification:show");
export const NOTIFICATION_DISMISS = createAction("notification:dismiss");
export const NOTIFICATION_CLEAR = createAction("notification:clear");

const defaultState = {
  list: []
};

// Reducer
const reducer = handleActions(
  {
    [NOTIFICATION_SHOW]: (state, { payload }) => ({
      ...state,
      list: [...state.list, payload]
    }),
    [NOTIFICATION_DISMISS]: (state, { payload }) => ({
      ...state,
      list: state.list.filter(notification => notification.id !== payload)
    }),
    [NOTIFICATION_CLEAR]: state => ({
      ...state,
      list: []
    })
  },
  defaultState
);

// Actions creators
export const dismissNotification = payload => ({
  type: NOTIFICATION_DISMISS,
  payload
});

export const clearNotifications = () => ({
  type: NOTIFICATION_CLEAR
});

// Thunks

export function showNotification(notif) {
  const payload = Object.assign({}, notif);

  if (!payload.id) payload.id = Date.now();

  return dispatch => {
    dispatch({ type: NOTIFICATION_SHOW, payload });

    if (payload.dismissAfter) {
      setTimeout(() => {
        dispatch({
          type: NOTIFICATION_DISMISS,
          payload: payload.id
        });
      }, payload.dismissAfter);
    }
  };
}

export const showSuccess = (message, options = {}) =>
  showNotification({ type: "success", message, ...options });

export const showWarning = (message, options = {}) =>
  showNotification({ type: "warning", message, ...options });

export const showError = (message, options = {}) =>
  showNotification({
    type: "error",
    message: `Error: ${message || ""}`,
    ...options
  });

// Selectors
export const notificationSelector = state => state.notification;
export const notificationListSelector = createSelector(
  notificationSelector,
  notification => notification.list
);

export default reducer;
