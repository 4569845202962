import seedrandom from "seedrandom";

const formatTheme = (theme) => theme.replace(" ", "_").toLowerCase();

const getQuestionType = (question) => {
  const answers = Object.values(question.answers).map(
    (v) => v.count + (v.publicCount || 0)
  );

  const pos = answers[0] || 0;
  const neu = answers[1] || 0;
  const neg = answers[2] || 0;

  const sum = pos + neu + neg;
  const count = [pos, neu, neg].map((c) => c / sum);
  count.sort();
  count.reverse();

  const diff01 = count[0] - count[1];
  const diff12 = count[1] - count[2];
  if (diff01 >= 0.18) {
    return 1;
  } else if (diff12 >= 0.14) {
    return 2;
  }
  return 3;
};

const getStringId = (questionId, question) => {
  const theme = question.theme
    ? formatTheme(question.theme)
    : "digital_catapult";
  const questionType = getQuestionType(question);
  const rnd = seedrandom(questionId + questionType);
  const attractorId = Math.floor(4 * rnd());

  return `${theme}_${questionType}_${attractorId}`;
};

export { getQuestionType, getStringId };
