import React from "react";
import PropTypes from "prop-types";
import { fieldPropTypes } from "redux-form";

import { FormControl, RadioGroup, FormHelperText } from "@material-ui/core";

const FormRadioGroup = ({
  input,
  meta: { touched, invalid, error },
  children,
  formControlProps = {},
  ...props
}) => (
  <FormControl error={touched && invalid} {...formControlProps}>
    <RadioGroup {...input} {...props}>
      {children}
    </RadioGroup>
    <FormHelperText>{touched && error}</FormHelperText>
  </FormControl>
);

FormRadioGroup.propTypes = {
  ...fieldPropTypes,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default FormRadioGroup;
