import CONFIG from "../../config/index.js";

const IS_PROD = [
  ...(Array.isArray(CONFIG.websiteUrl.live)
    ? CONFIG.websiteUrl.live
    : [CONFIG.websiteUrl.live]),
  CONFIG.websiteUrl.production,
  CONFIG.adminUrl,
].includes(window.location.origin);

const ROLES = {
  SUPER_ADMIN: { title: "super-admin", key: 0 },
  ADMIN: { title: "admin", key: 1 },
  EDITOR: { title: "editor", key: 2 },
  CONTRIBUTOR: { title: "contributor", key: 3 },
  SUBSCRIBER: { title: "subscriber", key: 4 },
};

const COLLECTIONS = {
  USERS: "users",
  QUESTIONS: "questions",
  ANALYTICS: "analytics",
  ASKED_QUESTIONS: "askedQuestions",
  DISPLAYS: "displays",
  TAGS: "tags",
  THEMES: "themes",
  TYPEFORM_TOKENS: "typeformTokens",
};

const IDLE_DURATION = 1000 * 60 * 5;

export { IS_PROD, ROLES, COLLECTIONS, IDLE_DURATION };
